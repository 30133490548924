import { render, staticRenderFns } from "./mention.vue?vue&type=template&id=b77d7f14&scoped=true&"
var script = {}
import style0 from "./mention.vue?vue&type=style&index=0&id=b77d7f14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b77d7f14",
  null
  
)

export default component.exports